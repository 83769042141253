import {MessageService} from "./MessageService";

export class BlockUiService{
    blockUi(){
        MessageService.sendMessage({
            title: TitleEnvelop.BLOCK_UI_ON,
            corpo: undefined
        });
    }
    unBlockUi(){
        MessageService.sendMessage({
            title: TitleEnvelop.BLOCK_UI_OFF,
            corpo: undefined
        });
    }
}

export class Envelope{
    title?: TitleEnvelop;
    corpo?: any;
}

export enum TitleEnvelop{
    BLOCK_UI_ON,
    BLOCK_UI_OFF,
}