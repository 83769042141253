import moment from "moment"
import VMasker from "vanilla-masker";

export class WebUtils {

    static onlyNumbers(val?: string): string | undefined {
        if (val) {
            return val.replace(/[^\d]+/g, '');
        }
        return val;
    }

    static isNullOrEmptyAndTrim(valor?: string): boolean {
        if (WebUtils.isNullOrEmpty(valor)) {
            return true;
        }

        if (valor!.trim().length === 0) {
            return true;
        }

        return false;
    }

    static handlerFlexBoxValue = (flexXsCol?: number, flexSmCol?: number, flexMdCol?: number, flexLgCol?: number) : string=> {
        if(!flexLgCol){
            flexLgCol = 12;
        }
        if(!flexMdCol){
            flexMdCol = flexLgCol;
        }
        if(!flexSmCol){
            flexSmCol = flexMdCol;
        }
        if(!flexXsCol){
            flexXsCol = flexSmCol;
        }
        let xs = " col-xs-" + flexXsCol;
        let sm = " col-sm-" + flexSmCol;
        let md = " col-md-" + flexMdCol;
        let lg = " col-lg-" + flexLgCol;
        let result = xs + md + sm + lg;
        return result
    };

    static isNullOrEmpty(valor?: string): boolean {
        if (!valor) {
            return true;
        }

        if (valor.length === 0) {
            return true;
        }

        return false;
    }

    static transaletStatusBoleto(value?: string) {
        if (value) {
            if (value === "pending") {
                return "Pendente PGTO";
            }
            return "Cancelado";
        }
        return undefined;
    }

    static startDownload(response: Blob, fileName: string) {
        const responseType = response as Blob;
        const blob = new Blob([responseType], {type: 'application/octet-stream'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.innerHTML = 'Click here to download the file';
        link.click();
        window.URL.revokeObjectURL(url);
    }

    static formatDoc(doc?: string): string {
        if (!this.isNullOrEmptyAndTrim(doc)) {
            switch (doc!.length) {
                case 11:
                    return this.formatCpf(doc!);
                case 14:
                    return this.formatCnpj(doc!);
                default:
                    return doc!;
            }
        }
        return "";
    }

    static formatCpf(cpf: string): string {
        const c = cpf.split('');
        if (c.length === 11) {
            return c[0] + c[1] + c[2] + '.' + c[3] + c[4] + c[5] + '.' + c[6] + c[7] + c[8] + '-' + c[9] + c[10];
        }
        return cpf;
    }

    static formatCnpj(cnpj: string): string {
        const c = cnpj.split('');
        if (c.length === 14) {
            return c[0] + c[1] + '.' + c[2] + c[3] + c[4] + '.' + c[5] + c[6] + c[7] + '/' + c[8] + c[9] + c[10] + c[11] + '-' + c[12] + c[13];
        }
        return cnpj;
    }

    formatPhone(telefone?: string): string | undefined {
        if (!telefone) {
            return telefone;
        }
        const telefoneTemp = WebUtils.onlyNumbers(telefone);
        if (telefoneTemp !== telefone) {
            return telefone;
        }

        const c = telefone.split('');
        if (c.length === 11) {
            return '(' + c[0] + c[1] + ')' + c[2] + '.' + c[3] + c[4] + c[5] + c[6] + '-' + c[7] + c[8] + c[9] + c[10];
        } else if (c.length === 10) {
            return '(' + c[0] + c[1] + ')' + c[2] + c[3] + c[4] + c[5] + '-' + c[6] + c[7] + c[8] + c[9];
        }
        return telefone;
    }

    static convertLongTimeMilis(timestampMilis?: number): string | undefined {
        if (timestampMilis) {
            return WebUtils.convertLongTimeMilisToMomentTime(timestampMilis)?.format(DatePatternEnum.DD_MM_YYYY_HH_MM_SS);
        }
        return undefined;
    }

    static convertLongTimeMilisToMomentTime(timestampMilis?: number): moment.Moment | undefined {
        if (timestampMilis) {
            return moment.unix(timestampMilis / 1000);
        }
        return undefined;
    }

    static convertLongTimeMilisDatePattern(timestampMilis?: number, pattern?: DatePatternEnum): string | undefined {
        if (timestampMilis && pattern) {
            return WebUtils.convertLongTimeMilisToMomentTime(timestampMilis)?.format(pattern);
        }
        return undefined;
    }

    static converterDataStrPattern(dataStr?: string, oldPattern?: DatePatternEnum, newPattern?: DatePatternEnum): string | undefined {
        if (dataStr && oldPattern && newPattern) {
            const d = moment(dataStr, oldPattern).toDate();
            return moment(d).format(newPattern);
        }
        return undefined;
    }

    static converterDataStrPatternToDate(dataStr?: string, oldPattern?: DatePatternEnum): Date | undefined {
        if (dataStr && oldPattern) {
            const d = moment(dataStr, oldPattern).toDate();
            return d;
        }
        return undefined;
    }

    // static compareDateAfter(startDate: Date, futureDate: Date): boolean {
    //   return moment(futureDate).isAfter(startDate);
    // }

    static compareDateAfterOrEquals(startDate: Date, futureDate: Date): boolean {
        if (WebUtils.convertDateToDatePattern(startDate, DatePatternEnum.DD_MM_YYYY) !== WebUtils.convertDateToDatePattern(futureDate, DatePatternEnum.DD_MM_YYYY)) {
            return moment(futureDate).isAfter(startDate);
        }
        return true;
    }

    static convertDateToDatePattern(date?: Date, oldPattern?: DatePatternEnum): string | undefined {
        if (date && oldPattern) {
            return moment(date).format(oldPattern);
        }
        return undefined;
    }

    static applyMask(licensePlate: string, pattern: string): string {
        return VMasker.toPattern(licensePlate, pattern);
    }

    //
    static addMonths(dueDate: Date, number: number): Date {
        return moment(dueDate).add(number, 'M').toDate();
    }

    static addDays(dueDate: Date, number: number): Date {
        return moment(dueDate).add(number, "day").toDate();
    }

    static validateDate(dataStr: string, pattern: string) {
        return moment(dataStr, pattern).isValid();
    }

    static validateDateFromPattern(dataStr?: string, newPattern?: string): boolean {

        if (dataStr && newPattern && (dataStr.length === newPattern.length)) {

            return moment(dataStr, newPattern).isValid();
        }
        return false;
    }

    static formatNumberPipe(tam?: number, casasDecimais?: number): string | undefined {
        if(!tam){
            return undefined
        }
        if (!casasDecimais) {
            casasDecimais = 2;
        }

        let tamStr = Number(tam).toLocaleString('pt-BR', WebUtils.nCasas(casasDecimais));

        return tamStr;
    }

    static nCasas(size: number): Intl.NumberFormatOptions {
        let duasCasas: Intl.NumberFormatOptions = {
            maximumFractionDigits: size,
            minimumFractionDigits: size
        };
        return duasCasas;
    }
}

export enum DatePatternEnum {
    DD_MM_YYYY = 'DD/MM/YYYY',
    MM_YYYY = 'MM/YYYY',
    YYYY_MM = 'YYYY/MM',
    YYYY_MM_DD = 'YYYY-MM-DD',

    DD_MM_YYYY_HH_MM_SS = 'DD/MM/YYYY HH:mm:ss',
    DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm',

    DD_MM_YYYY_HH_MM_SS_Z = 'DD/MM/YYYY HH:mm:ss Z',

    YYYY_MM_DDTHH_mm_ss_fffZ = 'YYYY-MM-DDTHH:mm:ss.fffZ',
    YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss',
    YYYY_MM_DD_HH_mm = 'YYYY-MM-DD HH:mm',
    YYYY_MM_DD_HH_mm_ss_ZZ = 'YYYY-MM-DD HH:mm:ss ZZ',
    YYYY_MM_DD_HHTmm_ssZ = 'YYYY-MM-DD[T]HH:mm:ssZ',
    YYYY_MM_DD_HHTmm_ss = 'YYYY-MM-DD[T]HH:mm:ss',
    YYYY_MM_DD_HHTmm_ssZZ = 'YYYY-MM-DD[T]HH:mm:ssZZ',

    ddd_MMM_DD_HH_mm_ss_YYYY_ZZ = 'ddd MMM DD HH:mm:ss YYYY ZZ'
}
