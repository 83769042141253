import "./button.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface ComponentProps {
    style: BtnStyle;
    label?: string;
    type?: string;
    disabled?: boolean;
    width?: number;
    icon?: IconProp;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = (props: ComponentProps) => {
    return (
        <button
            onClick={props.onClick}
            className={props.style}
            disabled={props.disabled}
        >
            {props.icon && <FontAwesomeIcon icon={props.icon}/>}

            {props.label ? " " + props.label : ""}
        </button>
    )
}

export enum BtnStyle {
    PRIMARY = "button primary",
    DANGER = "button danger",
    SECONDARY = "button secondary",
    TRANSPARENT = "button transparent"
}

export default Button
