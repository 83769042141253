import './App.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AppRoute} from "./models/Routers";
import StartView from "./views/startView/startView";
import NotFoundView from "./views/notFoundView/notFoundView";
import BlockUi from "./components/blockUi/blockUi";

function App() {

  return (
      <>
        <BlockUi ></BlockUi>
        <BrowserRouter basename="/">
          <Routes>
            <Route path={AppRoute.ROOT} element={<StartView/>}/>
            <Route path={AppRoute.START_PAGE} element={<StartView/>}/>
            <Route path={"/*"} element={<NotFoundView/>}/>
          </Routes>
        </BrowserRouter>
      </>
  );
}

export default App;
