import "./form.scss"
import {SearchBoleto} from "../../../models/boletoModels";
import React from "react";
import InputMaskDoc from "../../../components/input/inputMaskDoc";
import {setValue} from "../../../models/HtmlInput";
import InputMaskDate from "../../../components/input/inputMaskDate";
import Button, {BtnStyle} from "../../../components/button/button";
import {useFormik} from "formik";
import {DatePatternEnum, WebUtils} from "../../../models/webUtils";
import {MsgModel, MsgStyle} from "../../../components/messages/messages";


interface ComponentProps {
    setBoletoSearch:  React.Dispatch<SearchBoleto>;
    setMsg:  React.Dispatch<React.SetStateAction<MsgModel>>;
    getBoletos: (page: number)=>void;
    setFormValid: React.Dispatch<boolean>;
}
function FormListBoleto(prop: ComponentProps){

    let {
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
        submitForm
    } = useFormik({
        validateOnChange: true,
        initialValues: {startDate: "", endDate: "", clientDocument: ""},
        validate,
        onSubmit: (values) => {
            //setLogin(values);
        },
    });

    function validateSubmit() {
        let err = validate(values);
        submitForm();
        let val = Object.values(err).length;
        return val === 0;
    }

    function validate(values: SearchBoleto) {
        let errors: any = {};
        let sizeDoc = WebUtils.onlyNumbers(values.clientDocument)?.length;

        if (!sizeDoc || (sizeDoc !== 11 && sizeDoc !== 14)) {
            errors.clientDocument = "Campo obrigatório ou inválido";
        }


        if (values.startDate && values.startDate.length > 0) {
            if (!WebUtils.validateDateFromPattern(values.startDate, DatePatternEnum.DD_MM_YYYY)) {
                errors.startDate = "Formato inválido de data";
            }
        }
        if (values.endDate && values.endDate.length > 0) {
            if (!WebUtils.validateDateFromPattern(values.endDate, DatePatternEnum.DD_MM_YYYY)) {
                errors.endDate = "Formato inválido de data";
            }
        }

        let val = Object.values(errors).length;
        prop.setFormValid(val === 0);
        return errors;
    }

    function runGetBoletos(){
        prop.setMsg(new MsgModel(MsgStyle.INFO, undefined));
        if (!validateSubmit()) {
            prop.setMsg(new MsgModel(MsgStyle.ERROR, "Alguns campos precisam de sua atenção"));
            prop.setFormValid(false);
            return;
        }
        prop.getBoletos(1);
    }

    return (
        <>

            <form>
                <div className="row">
                    <InputMaskDoc
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Cpf/Cnpj*"
                        value={values.clientDocument}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e, prop.setBoletoSearch)}
                        inValid={(!!touched.clientDocument && !!errors.clientDocument)}
                        name={"clientDocument"}
                    />
                </div>
                <div className="row">
                    <InputMaskDate
                        label="Data Inicio"
                        flexLgCol={6}
                        flexXsCol={12}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.startDate}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e, prop.setBoletoSearch)}
                        inValid={(!!touched.startDate && !!errors.startDate)}
                        name={"startDate"}
                    />
                    <InputMaskDate
                        label="Data Fim"
                        flexLgCol={6}
                        flexXsCol={12}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endDate}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e, prop.setBoletoSearch)}
                        inValid={(!!touched.endDate && !!errors.endDate)}
                        name={"endDate"}
                    />
                </div>
            </form>
            <div className="row">
                <div className="col-xs-12">
                    <div className="button-max-with">
                        <Button style={BtnStyle.PRIMARY} label="buscar boletos" width={100}
                                onClick={e => runGetBoletos()}></Button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FormListBoleto
