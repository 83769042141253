import Button, {BtnStyle} from "../button";
import React from "react";
import {Paginator} from "../../../models/BackendDefaults";
import "./paginator.scss";
import {faBackwardFast} from "@fortawesome/free-solid-svg-icons/faBackwardFast";
import {faBackwardStep} from "@fortawesome/free-solid-svg-icons/faBackwardStep";
import {faForwardStep} from "@fortawesome/free-solid-svg-icons/faForwardStep";
import {faForwardFast} from "@fortawesome/free-solid-svg-icons";


interface ComponentProps {
    paginator: Paginator<any>
    setCurrentPage:  (page: number) => void;
    positionStyle?: string;
}

const BtnPaginator = (props: ComponentProps) => {

    const first = () => {
        if(props.setCurrentPage && props.paginator.currentPage) {
            props.setCurrentPage(1);
        }
    };
    const next = () => {
        if(props.setCurrentPage && props.paginator.currentPage && props.paginator.maxPages && (props.paginator.maxPages>props.paginator.currentPage)) {
            props.setCurrentPage(1 + props.paginator.currentPage);
        }
    };
    const previous = () => {
        if(props.setCurrentPage && props.paginator.currentPage) {
            let value = props.paginator.currentPage - 1;
            props.setCurrentPage(value < 1 ? 1:value);
        }
    };
    const last = () => {
        if(props.setCurrentPage && props.paginator.maxPages) {
            props.setCurrentPage(props.paginator.maxPages);
        }
    };

    return (
        <div className={props.positionStyle ? props.positionStyle : 'paginator-area'}>
            <Button icon={faBackwardFast} style={BtnStyle.TRANSPARENT} onClick={first}></Button>
            <Button icon={faBackwardStep} style={BtnStyle.TRANSPARENT} onClick={previous}></Button>
            <Button icon={faForwardStep} style={BtnStyle.TRANSPARENT} onClick={next}></Button>
            <Button icon={faForwardFast} style={BtnStyle.TRANSPARENT} onClick={last}></Button>
        </div>
    )
}

export default BtnPaginator
