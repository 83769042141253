import React from "react";

export interface HtmlInput{
    value?: string | number;
    name?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onInput?: React.ChangeEventHandler<HTMLInputElement>;
    readOnly?: boolean;
    maxLength?: number;
    disabled?: boolean;
}


export function setValue(e: React.ChangeEvent<HTMLInputElement>, setValue: React.SetStateAction<any>) {
    const {name, value} = e.target;
    setValue((prev:any) => ({
        ...prev,
        [name]: value
    }));
}
