import {Paginator} from "./BackendDefaults";

export class SearchBoleto {
    issuerDocument?: string;
    clientDocument: string = "";
    startDate?: string;
    endDate?: string;
    limitPerPage?: number;
}

export class PaginatorBoletoSearch extends Paginator<BoletoSearch>{

}

export class BoletoSearch {
    idBill?: number
    idInstallment?: number
    issuerName?: string
    dueDate?: string
    issuerDocument?: string
    statusBoleto?: string
    nameSocialReason?: string
    value?: number
    clientDocument?: string
}

export class DownloadBoleto{
    issuerDocument?: string;
    idInstallment?: number;
    idBill?: number;
    newValue?: number;
    newPayDay?: string;
}