
export class Paginator<T>{
    total?: number;
    maxPages?: number;
    currentPage?: number;
    maxPerPage?: number;
    records: T[] = [];
}

export class ResponseBackend<T, E>{
    dateTime: string | undefined;
    endPointValidate: string | undefined;
    success: boolean | undefined;
    body: T | undefined;
    extraBody: E | undefined;
}