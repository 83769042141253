import './messages.scss';
import {useEffect, useState} from "react";

interface ComponentProps {
    msg?: MsgModel;
}

const Messages = (props: ComponentProps) => {
    const [showMsg, setShowMsg] = useState<boolean>(true);
    const [displayV, setdisplayV] = useState('block');

    useEffect(() => {
        if (!showMsg) {
            setdisplayV('none');
        } else {
            setdisplayV('block');
        }
    }, [showMsg]);

    useEffect(() => {
        if(props.msg && props.msg.msg && props.msg?.msg.length>0){
            setShowMsg(true);
        }else{
            setShowMsg(false);
        }
    }, [props.msg]);

    return (
        <>
            <div style={{display: displayV}}>
                <div className={'message-alert ' + props.msg?.tipo}>{props.msg?.msg}
                    <button onClick={e => setShowMsg(false)}>X</button>
                </div>
            </div>
        </>
    )
}


export enum MsgStyle {
    INFO = "info",
    ERROR = "error",
    WARN = "warn"
}

export class MsgModel{

    constructor(t: MsgStyle, m?: string | undefined){
        this.tipo = t;
        this.msg = m;
    }

    tipo: MsgStyle = MsgStyle.INFO;
    msg?: string | undefined;
}

export default Messages;