import "./blockUi.scss"
import React, {useState} from "react";
import {Envelope, TitleEnvelop} from "../../service/rxJsServices/blockUiService";
import {MessageService} from "../../service/rxJsServices/MessageService";

const BlockUi = () => {

    const [show, setShow] = useState<boolean>(false);


    function listenerBlockUi(): void {
        MessageService.getMessage().subscribe(msg => {
            let m = msg as Envelope;
            if (m.title === TitleEnvelop.BLOCK_UI_ON) {
                BlockUiCounter.qtd++;
                setShow(true);

            } else if (m.title === TitleEnvelop.BLOCK_UI_OFF) {
                BlockUiCounter.qtd--;
                let val = BlockUiCounter.qtd;
                if (val <= 0) {
                    setShow(false);
                    BlockUiCounter.qtd = 0;
                }
            }
        });
    }

    listenerBlockUi();


    return (
        <>
            {show &&
                <div className={'blockui-area'}>
                    <div className={'area-icon'}>
                        <img className="logo" src="/assets/imgs/loading.svg" alt="logo"></img>
                    </div>
                </div>
            }
        </>
    )
}

export default BlockUi

export class BlockUiCounter {
    public static qtd = 0;
}
