import React from "react";
import "./notFoundView.scss"



function NotFoundView() {
    return (
        <div className={'notFoundImgArea'} >

            <img className="logo" src="/assets/imgs/notFound.png" alt="logo"></img>

        </div>
    );
}

export default NotFoundView;


