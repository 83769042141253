import {HtmlInput} from "../../models/HtmlInput";
import "./input.scss"
import VMasker from "vanilla-masker";
import {DatePatternEnum, WebUtils} from "../../models/webUtils";

interface ComponentProps extends HtmlInput {
    style?: string;
    label?: string;
    type?: string;
    width?: number;
    inValid?: boolean;
    flexXsCol?: number;
    flexSmCol?: number;
    flexMdCol?: number;
    flexLgCol?: number;
}

const InputMaskDate = (props: ComponentProps) => {


    const applyMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        let mask = "99/99/9999";
        e.target.value = VMasker.toPattern(e.target.value, mask);
        if (props.name && props.onInput) {
            props.onInput(e);
        }
    }

    const applyActions = (e: React.ChangeEvent<HTMLInputElement>) => {
        applyMask(e);
        if (validateData(e.target.value) && props.onChange) {
            props.onChange(e);
        } else if(props.onChange){
            let valueFinal = e.target.value;
            e.target.value = valueFinal.substring(0, valueFinal.length - 1);
            props.onChange(e);
        }
        if(props.onChange) {
            props.onChange(e)
        }
    }

    function validateData(val?: string) {
        if (val) {
            switch (val.length) {
                case (1):
                    return WebUtils.validateDateFromPattern(`${val}1/12/2012`, DatePatternEnum.DD_MM_YYYY);
                case (2):
                    return WebUtils.validateDateFromPattern(`${val}/12/2012`, DatePatternEnum.DD_MM_YYYY);
                case (3):
                    return WebUtils.validateDateFromPattern(`${val}12/2012`, DatePatternEnum.DD_MM_YYYY);
                case (4):
                    return WebUtils.validateDateFromPattern(`${val}1/2012`, DatePatternEnum.DD_MM_YYYY) || WebUtils.validateDateFromPattern(`${val}0/2012`, DatePatternEnum.DD_MM_YYYY);
                case (5):
                    return WebUtils.validateDateFromPattern(`${val}/2012`, DatePatternEnum.DD_MM_YYYY);
                case (6):
                    return WebUtils.validateDateFromPattern(`${val}2012`, DatePatternEnum.DD_MM_YYYY);
                case (7):
                    return WebUtils.validateDateFromPattern(`${val}012`, DatePatternEnum.DD_MM_YYYY);
                case (8):
                    return WebUtils.validateDateFromPattern(`${val}12`, DatePatternEnum.DD_MM_YYYY);
                case (9):
                    return true;
                case (10):
                    return WebUtils.validateDateFromPattern(`${val}`, DatePatternEnum.DD_MM_YYYY);
                default:
                    return false;
            }
        }
        return false;
    }

    return (
        <div className={WebUtils.handlerFlexBoxValue(props.flexXsCol, props.flexSmCol, props.flexMdCol, props.flexLgCol)}>
            <div className="box">
                <div className="input-area">
                    <label className={props.inValid ? "error" : ""} >{props.label}</label>
                    <input
                        className={props.inValid ? "inputtext error" : "inputtext"}
                        value={props.value}
                        onInput={props.onInput}
                        name={props.name}
                        disabled={props.disabled}
                        onChange={(e)=> applyActions(e)}
                        onBlur={props.onBlur}
                    />
                    { props.inValid &&
                        <small className={"error"} >Campo inválido</small>
                    }
                </div>
            </div>
        </div>
    )
}

export default InputMaskDate
