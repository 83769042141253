import {HtmlInput} from "../../models/HtmlInput";
import "./input.scss"
import VMasker from "vanilla-masker";
import {WebUtils} from "../../models/webUtils";

interface ComponentProps extends HtmlInput {
    style?: string;
    label?: string;
    type?: string;
    width?: number;
    inValid?: boolean;
    flexXsCol?: number;
    flexSmCol?: number;
    flexMdCol?: number;
    flexLgCol?: number;
}

const InputMaskDoc = (props: ComponentProps) => {

    const applyMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        let mask = "999.999.999-99";
        if (e.target.value.length > 14) {
            mask = "99.999.999/9999-99";
        }
        e.target.value = VMasker.toPattern(e.target.value, mask);
        if (props.name && props.onInput) {
            props.onInput(e);
        }
    }
    const applyActions = (e: React.ChangeEvent<HTMLInputElement>) => {
        applyMask(e);
        if(props.onChange) {
            props.onChange(e)
        }
    }

    return (
        <div className={WebUtils.handlerFlexBoxValue(props.flexXsCol, props.flexSmCol, props.flexMdCol, props.flexLgCol)}>
            <div className="box">
                <div className="input-area">
                    <label className={props.inValid ? "error" : ""} >{props.label}</label>
                    <input
                        className={props.inValid ? "inputtext error" : "inputtext"}
                        value={props.value}
                        onInput={props.onInput}
                        name={props.name}
                        disabled={props.disabled}
                        onChange={(e) => applyActions(e)}
                        onBlur={props.onBlur}
                    />
                    { props.inValid &&
                    <small className={"error"} >Campo obrigatório/Inválido</small>
                    }
                </div>
            </div>
        </div>
    )
}

export default InputMaskDoc
