import {BoletoSearch, PaginatorBoletoSearch} from "../../../models/boletoModels";
import Button, {BtnStyle} from "../../../components/button/button";
import Paginator from "../../../components/button/paginator/paginator";
import {DatePatternEnum, WebUtils} from "../../../models/webUtils";
import "./listData.scss"

interface ComponentProps {
    msgBase?: string;
    paginator: PaginatorBoletoSearch;
    downloadBoleto: (bol: BoletoSearch) => void;
    getBoletos: (page: number)=>void;
}
function ListBoleto(prop: ComponentProps) {

    function formatShowNumber(value: number | undefined): string | undefined {
        if (value) {
            return "R$ " + WebUtils.formatNumberPipe(value);
        }
        return "";
    }

    function formatShowData(dueDate: string | undefined) {
        return WebUtils.converterDataStrPattern(dueDate, DatePatternEnum.YYYY_MM_DD, DatePatternEnum.DD_MM_YYYY);
    }

    function translate(statusBoleto: string | undefined) {
        return WebUtils.transaletStatusBoleto(statusBoleto)
    }
    function setPage(number: number) {
        prop.getBoletos(number);
    }


    return (
        <>
            {
                (prop.paginator.records.length === 0) &&
            <div className="not-found">
                {prop.msgBase ? prop.msgBase : "Preencha os campos e realize a consulta"}
            </div>
            }
            {
                (prop.paginator.records.length > 0) &&
                <div className="base-area">
                    <div className="row search-area">
                        <div className="col-xs-12">
                            <div>
                                <span
                                    className="name-social-reason">Razão social: {prop.paginator.records[0].nameSocialReason}</span>
                            </div>

                            {prop.paginator.records.map((bol: BoletoSearch, idx: number) =>
                                <div key={idx} className="box">
                                    <div className="left">
                                        <span><b>Vencimento:</b> {formatShowData(bol.dueDate)}</span>
                                        <span><strong>Valor:</strong> {formatShowNumber(bol.value)}</span>
                                        <span><strong>Status:</strong> {translate(bol.statusBoleto)}</span>
                                    </div>
                                    <div className="right">
                                        <div className="area-btn">
                                            <Button label="Download" style={BtnStyle.PRIMARY}
                                                    onClick={() => prop.downloadBoleto(bol)}/>
                                        </div>
                                    </div>
                                    <div className="end"></div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Paginator paginator={prop.paginator} setCurrentPage={setPage}></Paginator>
                </div>
            }
        </>
    )
}

export default ListBoleto
