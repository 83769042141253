import React, {useState} from "react";
import {BoletoService} from "../../service/boletoService/boletoService";
import {BoletoSearch, DownloadBoleto, PaginatorBoletoSearch, SearchBoleto} from "../../models/boletoModels";
import {AxiosError} from "axios";
import "./startView.scss"
import {DatePatternEnum, WebUtils} from "../../models/webUtils";
import Messages, {MsgModel, MsgStyle} from "../../components/messages/messages";
import {BlockUiService} from "../../service/rxJsServices/blockUiService";
import ListBoleto from "./listData/listData";
import FormListBoleto from "./formSearch/form";

function StartView() {

    const [boletoService] = useState<BoletoService>(new BoletoService());
    const [records, setRecords] = useState<PaginatorBoletoSearch>(new PaginatorBoletoSearch());
    const [boletoSearch, setBoletoSearch] = useState<SearchBoleto>(new SearchBoleto());
    const [formValid, setFormValid] = useState<boolean>(false);
    const [msg, setMsg] = useState<MsgModel>(new MsgModel(MsgStyle.INFO, undefined));
    const [msgBase, setMsgBase] = useState<string | undefined>(undefined);
    const [blockUiService] = useState<BlockUiService>(new BlockUiService());

    function handlerDate(value: string | undefined) {
        if (value?.trim().length === 0) {
            return undefined
        } else {
            value = WebUtils.converterDataStrPattern(value, DatePatternEnum.DD_MM_YYYY, DatePatternEnum.YYYY_MM_DD);
        }
        return value;
    }

    function getSearchValues() {
        let param: SearchBoleto = {
            issuerDocument: "79305130259",
            clientDocument: WebUtils.onlyNumbers(boletoSearch.clientDocument)!,
            startDate: handlerDate(boletoSearch.startDate),
            endDate: handlerDate(boletoSearch.endDate),
            limitPerPage: 3
        }
        return param;
    }

    const getBoletos = (page: number) => {
        let param = getSearchValues();
        if(!formValid){
            setRecords(new PaginatorBoletoSearch());
            setMsgBase(undefined);
            return;
        }
        blockUiService.blockUi();
        boletoService.getBoletoList(param, page)
            .then(response => {
                if (response.data.body) {
                    setRecords(response.data.body);
                    if (records.records.length === 0) {
                        setMsgBase("Nenhum boleto encontrado para o Cpf/Cnpj: " + WebUtils.formatDoc(param.clientDocument));
                    }
                }
            })
            .catch((err: AxiosError) => {
                console.log(err.message);
                setMsg(new MsgModel(MsgStyle.ERROR, err.message));
            })
            .finally(() => {
                blockUiService.unBlockUi();
            })
    };

    //antiga versão para registrar valor no input mantendo como exemplo
/*    function setBoletoValue(e: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = e.target;
        setBoletoSearch(prev => ({
            ...prev,
            [name]: value
        }));
    }*/


    function getParam(bol: BoletoSearch) {

        let finalParam: DownloadBoleto = {
            issuerDocument: "79305130259",
            idBill: bol.idBill,
            idInstallment: bol.idInstallment,
            newPayDay: handlerPayDayValue(bol),
            newValue: handlerNewValue(bol)
        }
        return finalParam;
    }

    function handlerPayDayValue(donwloadData: BoletoSearch) {
        if (donwloadData.statusBoleto !== "pending") {
            let dueDate = WebUtils.compareDateAfterOrEquals(new Date(), WebUtils.converterDataStrPatternToDate(donwloadData.dueDate, DatePatternEnum.YYYY_MM_DD)!)
            let todayPlus1: Date;
            if (dueDate) {
                todayPlus1 = WebUtils.converterDataStrPatternToDate(donwloadData.dueDate, DatePatternEnum.YYYY_MM_DD)!
            } else {
                todayPlus1 = WebUtils.addDays(new Date(), 1);
            }
            return WebUtils.convertDateToDatePattern(todayPlus1, DatePatternEnum.YYYY_MM_DD);
        }
        return undefined;
    }

    function handlerNewValue(donwloadData: BoletoSearch) {
        if (donwloadData.statusBoleto !== "pending") {
            return donwloadData.value;
        }
        return undefined;
    }


    function downloadBoleto(bol: BoletoSearch) {
        blockUiService.blockUi();
        let param = getParam(bol)
        boletoService.donwloadBoleto(param)
            .then(response => {
                WebUtils.startDownload(response.data, "boleto.pdf");
            })
            .catch(err => {
                setMsg(new MsgModel(MsgStyle.ERROR, "Falha ao realizar download"));
                console.error(err);
            })
            .finally(() => blockUiService.unBlockUi());
    }

    return (
        <div className="area">
            <img className="logo" src="/assets/imgs/kpsistemas.png" alt="logo"></img>
            <Messages msg={msg}/>
            <FormListBoleto getBoletos={getBoletos} setBoletoSearch={setBoletoSearch} setMsg={setMsg} setFormValid={setFormValid} ></FormListBoleto>
            <ListBoleto getBoletos={getBoletos} downloadBoleto={downloadBoleto} msgBase={msgBase} paginator={records} ></ListBoleto>
        </div>
    );
}

export default StartView;


